import React from 'react'

const Footer = () => {
  return (
    <div>
          <div id="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <p>&copy; Copyright <a >Saishraddha Polymers</a>. All Rights Reserved By saishradhha Polymers</p>
                    </div>
                    <div className="col-md-6">
                        <p>Designed by <a href="https://shreedigimarketing.in">Shree Digital Marketing Agency</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer