import logo from './logo.svg';
import './App.css';
import Navrouter from './Navrouter';
import Footer from './Footer';
import { Route, BrowserRouter as Router,Routes }from 'react-router-dom'
import Home from './Home';
import About from './About';
import Shop from './Shop';
import Contact from './Contact';
import Features from './Features';


function App() {
  return (
    <div>
    <Router>
      <Navrouter/>
      <Routes>
      <Route path='/' element={<Home/>}/>       
      <Route path='/about' element={<About/>}/>  
      <Route path='/shop' element={<Shop/>}/> 
      <Route path='/features' element={<Features/>}/> 
      <Route path='/contact' element={<Contact/>}/> 
      </Routes>
          <Footer/>
    </Router>
    
    </div>
  );
}

export default App;
