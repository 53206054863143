import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import video1 from './videos/sashradhha2.mp4';
import video2 from './videos/saishradhha1.mp4';

const About = () => {
  return (
    <div className="about-page">
      {/* Top image with "About Us" text */}
      <div className="about-banner">
        <img src="img/about.jpg" alt="About Us Banner" className="banner-image" />
        <div className="banner-overlay">
        
        </div>
      </div>

      <div className="about-container">
        <h2>Welcome to Saishraddha Polymers</h2>

        <h2>Our Products</h2>
        <p>
          Our mainstay product is polypropylene mats. By using PP tubes that are woven together...
        </p>

        <div className="product-grid">
          <img src="img/im1.jpg" alt="Product 1" />
          <img src="img/img2.jpg" alt="Product 2" />
          <img src="img/img3.jpg" alt="Product 3" />
          <img src="img/img4.jpg" alt="Product 4" />
          <img src="img/img5.jpg" alt="Product 5" />
          <img src="img/img6.jpg" alt="Product 6" />
        </div>
        <div className="view-all-button">
          <Link to='/shop'><button>View All</button></Link>
        </div>
      </div>

      {/* Our Company Section */}
      <div className="our-company-section">
        <h2>Our Company</h2>
        <p>Learn more about Saishraddha Polymers through these videos:</p>
        <div className="video-grid">
        <video controls>
            <source src={video1} type="video/mp4" />
          </video>
          <video controls>
            <source src={video2} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default About;
