import React, { useState } from 'react'
import'./Contact.css'

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Construct WhatsApp message
        const whatsappMessage = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
        const whatsappNumber = 'your-whatsapp-number'; // Replace with your number in international format
        const whatsappLink = `https://wa.me/${9405960967}?text=${encodeURIComponent(whatsappMessage)}`;

        // Open WhatsApp with pre-filled message
        window.open(whatsappLink, '_blank');
    };
  return (
    <div >
         <div id="contact">
            <div class="container-fluid">
                <h1 className='contact-head'>Contact US</h1>
                <div class="row">
                <div class="col-md-6">
         
                <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
            </div>
            <button type="submit" className="btn btn-primary">Send</button>
        </form>
</div>

                    <div class="col-md-6">
                        <div class="contact-info">
                            <h2>Get in Touch</h2>
                            <p>
                            We’re here to assist you with any inquiries or support you need. Reach out today and let us help you find the best solutions!                            </p>
                            <h3><i class="fa fa-map-marker"></i>Sai shradha polymers S 116 MIDC Jalgaon</h3>
                            <h3><i class="fa fa-envelope"></i>business@saishraddhapolymer.com</h3>
                            <h3><i class="fa fa-phone"></i>94059 60967 </h3>
                            <a class="btn" href="tel:94059 60967">Contact Us</a>
                            <div class="social">
                                <a href="tel:94059 60967"><i class="fab fa-twitter"></i></a>
                                <a href="https://www.facebook.com/p/Sai-shradha-polymers-V-96-MIDC-Jalgaon-MO-9405960967-8485895889-100066434952453/"><i class="fab fa-facebook-f"></i></a>
                                <a href="https://wa.link/4jcie7"><i class="fab fa-linkedin-in"></i></a>
                                <a href=""><i class="fab fa-instagram"></i></a>
                                <a href="https://wa.link/4jcie7"><i class="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.352098247462!2d75.6089284!3d20.978518599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd973f4cbf2dd05%3A0xe2efc4de17fb6fa5!2sSai%20shradha%20polymers!5e0!3m2!1sen!2sin!4v1725701159834!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Contact