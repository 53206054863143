import React from 'react'

const Shop = () => {
  return (
    <div>
           <div id="products">
            <div class="container">
                <div class="section-header">
                    <p>Affordable</p>
                    <h1>Price</h1>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                            <div class="product-img">
                                <img src="img/img2.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                          
                            <div class="product-img">
                                <img src="img/img3.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                         
                            <div class="product-img">
                                <img src="img/img4.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/im1.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                               
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                          
                            <div class="product-img">
                                <img src="img/img5.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                          
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/img6.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                              
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/010.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                              
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/015.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                              
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/016.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                              
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/019 (1).jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                              
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="product-single">
                           
                            <div class="product-img">
                                <img src="img/021.jpg" alt="Product Image"/>
                            </div>
                            <div class="product-footer">
                              
                                <a class="btn" href="https://wa.link/4jcie7">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Shop