import React from 'react'

const Features = () => {
  return (
    <div> <div id="feature">
    <div className="container">
        <div className="section-header">
            <p>Unique</p>
            <h1>Features of Our Mats</h1>
        </div>
        <div className="row align-items-center">
            <div className="col-md-4">
                <div className="product-feature">
                    <div className="product-content">
                        <h2>Anti-Slip Surface</h2>
                        <p>Provides excellent grip to prevent slips and falls, ensuring safety during use.</p>
                    </div>
                    <div className="product-icon">
                        <i className="fa fa-tachometer-alt"></i>
                    </div>
                </div>
                <div className="product-feature">
                    <div className="product-content">
                        <h2>Durable Material</h2>
                        <p>Made from high-density materials to withstand heavy use and last longer.</p>
                    </div>
                    <div className="product-icon">
                        <i className="fa fa-shield-alt"></i>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="product-img">
                    <img src="img/slender-young-woman-doing-yoga-exercise-isolated-white-background_231208-9274.jpg" alt="Mat Features"/>
                </div>
            </div>
            <div className="col-md-4">
                <div className="product-feature">
                    <div className="product-icon">
                        <i className="fa fa-cogs"></i>
                    </div>
                    <div className="product-content">
                        <h2>Easy Maintenance</h2>
                        <p>Water-resistant and easy to clean, maintaining hygiene with minimal effort.</p>
                    </div>
                </div>
                <div className="product-feature">
                    <div className="product-icon">
                        <i className="fa fa-umbrella"></i>
                    </div>
                    <div className="product-content">
                        <h2>Weather Resistant</h2>
                        <p>Designed to endure various weather conditions, suitable for both indoor and outdoor use.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></div>
  )
}

export default Features