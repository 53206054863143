import React from 'react';
import { Link } from 'react-router-dom';

const Navrouter = () => {
  const handleNavLinkClick = () => {
    // This function manually triggers the collapse of the navbar
    const navbarCollapse = document.getElementById('navbarCollapse');
    if (navbarCollapse) {
      // Check if the navbar is currently expanded
      if (navbarCollapse.classList.contains('show')) {
        // If it is, remove the 'show' class to collapse it
        navbarCollapse.classList.remove('show');
      }
    }
  };

  return (
    <div id="nav">
      <div className="navbar navbar-expand-md bg-light navbar-light">
        <a href="https://htmlcodex.com/product-landing-page-template" className="navbar-brand">sai shradhha polymer</a>
        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item"><Link to="/" className="nav-link" onClick={handleNavLinkClick}>Home</Link></li>
            <li className="nav-item"><Link to="/about" className="nav-link" onClick={handleNavLinkClick}>About</Link></li>
            <li className="nav-item"><Link to="/features" className="nav-link" onClick={handleNavLinkClick}>Features</Link></li>
            <li className="nav-item"><Link to="/shop" className="nav-link" onClick={handleNavLinkClick}>Shop</Link></li>
            <li className="nav-item"><Link to="/contact" className="nav-link" onClick={handleNavLinkClick}>Contact</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navrouter;
