import React, { useState } from 'react'
import './Home.css'

const Home = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Construct WhatsApp message
        const whatsappMessage = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
        const whatsappNumber = 'your-whatsapp-number'; // Replace with your number in international format
        const whatsappLink = `https://wa.me/${9405960967}?text=${encodeURIComponent(whatsappMessage)}`;

        // Open WhatsApp with pre-filled message
        window.open(whatsappLink, '_blank');
    };
  return (
    <div>
       
        <div id="header">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 d-md-none d-block">
                     
                    </div>
                    <div className="col-md-6">
                        <div className="header-content">
                            <h2>Introducing Our <br/>Latest <span>Mats</span></h2>
                            <p>
                            Welcome to Sai Shradhha Polymer! Discover our Range of Premium Mats Designed for Comfort and Style. Explore our Collection to Find the Perfect Mat that Fits your Needs and Enhances your Space.                            </p>
                            <a className="btn" href="tel:94059 60967">Buy Now</a>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-block d-none"> 
                        <img src='img/home.png' alt='mat'></img>
                       
                    </div>
                </div>
            </div>
        </div>
     
        <div id="feature-mini">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <i className="fa fa-cogs"></i>
              <h3>Durability</h3>
              <p>Built to last with strong materials</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <i className="fa fa-leaf"></i>
              <h3>Eco-friendly</h3>
              <p>Made from sustainable resources</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <i className="fa fa-bolt"></i>
              <h3>High Performance</h3>
              <p>Optimized for maximum efficiency</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <i className="fa fa-lock"></i>
              <h3>Safety</h3>
              <p>Ensures high-level protection</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="feature">
    <div className="container">
        <div className="section-header">
            <p>Unique</p>
            <h1>Features of Our Mats</h1>
        </div>
        <div className="row align-items-center">
            <div className="col-md-4">
                <div className="product-feature">
                    <div className="product-content">
                        <h2>Anti-Slip Surface</h2>
                        <p>Provides excellent grip to prevent slips and falls, ensuring safety during use.</p>
                    </div>
                    <div className="product-icon">
                        <i className="fa fa-tachometer-alt"></i>
                    </div>
                </div>
                <div className="product-feature">
                    <div className="product-content">
                        <h2>Durable Material</h2>
                        <p>Made from high-density materials to withstand heavy use and last longer.</p>
                    </div>
                    <div className="product-icon">
                        <i className="fa fa-shield-alt"></i>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="product-img">
                    <img src="img/slender-young-woman-doing-yoga-exercise-isolated-white-background_231208-9274.jpg" alt="Mat Features"/>
                </div>
            </div>
            <div className="col-md-4">
                <div className="product-feature">
                    <div className="product-icon">
                        <i className="fa fa-cogs"></i>
                    </div>
                    <div className="product-content">
                        <h2>Easy Maintenance</h2>
                        <p>Water-resistant and easy to clean, maintaining hygiene with minimal effort.</p>
                    </div>
                </div>
                <div className="product-feature">
                    <div className="product-icon">
                        <i className="fa fa-umbrella"></i>
                    </div>
                    <div className="product-content">
                        <h2>Weather Resistant</h2>
                        <p>Designed to endure various weather conditions, suitable for both indoor and outdoor use.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

   
     
        <div id="call-to-action">
            <div className="container">
                <h2>Call To Action</h2>
                <a className="btn" href="tel:94059 60967">Click Here To Call</a>
            </div>
            <img src="img/preview.png" alt='img1'/>
        </div>

        <div id="products">
            <div className="container">
                <div className="section-header">
                    <p>Affordable</p>
                    <h1>Price</h1>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6">
                        <div className="product-single">
                            <div className="product-title">
                            </div>
                            <div className="product-img">
                                <img src="img/img2.jpg" alt="Product Image"/>
                            </div>
                            <div className="product-footer">
                                <a className="btn" href="tel:94059 60967">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="product-single">
                            <div className="product-title">
                            </div>
                            <div className="product-img">
                                <img src="img/img3.jpg" alt="Product Image"/>
                            </div>
                            <div className="product-footer">
                              
                                <a className="btn" href="tel:94059 60967">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="product-single">
                            <div className="product-title">
                            </div>
                            <div className="product-img">
                                <img src="img/img4.jpg" alt="Product Image"/>
                            </div>
                            <div className="product-footer">
                                <a className="btn" href="tel:94059 60967">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="product-single">
                            <div className="product-title">
                            </div>
                            <div className="product-img">
                                <img src="img/img5.jpg" alt="Product Image"/>
                            </div>
                            <div className="product-footer">
                                <a className="btn" href="tel:94059 60967">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="contact">
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-6">
         
                <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
            </div>
            <button type="submit" className="btn btn-primary">Send</button>
        </form>

</div>

                    <div className="col-md-6">
                        <div className="contact-info">
                            <h2>Get in Touch</h2>
                            <p>
                            We’re here to assist you with any inquiries or support you need. Reach out today and let us help you find the best solutions!                            </p>
                            <h3><i className="fa fa-map-marker"></i>Sai shradha polymers S116 MIDC Jalgaon</h3>
                            <h3><i className="fa fa-envelope"></i>business@saishraddhapolym</h3>
                            <h3><i className="fa fa-phone"></i>9405960967 </h3>
                            <a className="btn" href="tel:tel:94059 60967">Contact Us</a>
                            <div className="social">
                                <a href="tel:94059 60967"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.facebook.com/p/Sai-shradha-polymers-V-96-MIDC-Jalgaon-MO-9405960967-8485895889-100066434952453/"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://wa.link/4jcie7"><i className="fab fa-linkedin-in"></i></a>
                                <a href=""><i className="fab fa-instagram"></i></a>
                                <a href="https://wa.link/4jcie7"><i className="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home